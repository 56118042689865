import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import CryptoJS from 'crypto-js';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Fonts from './components/Font/Font';
import { useAuth } from './context/Auth';
import EmailNotifications from './features/AccountSettings/EmailNotifications';
import OverageBilling from './features/AccountSettings/OverageBilling';
import PasswordReset from './features/AccountSettings/PasswordReset';
import ForgotPasswordPage from './features/Auth/ForgotPasswordPage';
import LoginPage from './features/Auth/LoginPage';
import ResetPasswordPage from './features/Auth/ResetPassword';
import DashboardPage from './features/Dashboard';
import PricingPage from './features/Pricing/Pricing';
import globalStyles from './styles';
import { theme } from './styles/theme';
import { supabase } from './supabaseClient';

// import NoCodeSolutions from './features/AccountSettings/NoCodeSolutions';
const NotFound = React.lazy(() => import('./components/NotFound'));
const ProfileSettings = React.lazy(
  () => import('./features/AccountSettings/ProfileSettings')
);

const App = () => {
  const { user } = useAuth();

  supabase.auth.onAuthStateChange((event, session) => {
    const cookieDomain = process.env.NODE_ENV == 'development' ? 'localhost' : 'supernovaapi.com';
    if (event === 'SIGNED_OUT') {
      // delete cookies on sign out
      const expires = new Date(0).toUTCString();
      document.cookie = `supernova-access-token=; Domain=${cookieDomain}; path=/; expires=${expires}; SameSite=Lax; secure`;
      // CMT: might need it later on
      // document.cookie = `my-refresh-token=; Domain=${cookieDomain}; path=/; expires=${expires}; SameSite=Lax; secure`;
    } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
      const maxAge = 7 * 24 * 60 * 60 // 7 days
      document.cookie = `supernova-access-token=${session.access_token}; Domain=${cookieDomain}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      // CMT: might need it later on
      // document.cookie = `my-refresh-token=${session.refresh_token}; Domain=${cookieDomain}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
    }
  })

  useEffect(() => {
    if (user) {
      const hmac = CryptoJS.HmacSHA256(
        user.id,
        'fqxvamKCqe_9bzO8FUzZT2hK51s2qhXCZrx3dHeF'
      );
      const userHash = hmac.toString(CryptoJS.enc.Hex);
      supabase
        .from('user_profiles')
        .select('*')
        .match({ id: user.id })
        .then(({ data }) => {
          let userNames = {};
          if (data && data[0]?.first_name && data[0]?.last_name) {
            userNames = {
              name: `${data[0]?.first_name} ${data[0]?.last_name}`,
              first_name: data[0]?.first_name,
              last_name: data[0]?.last_name,
            };
          } else if (data && data[0]?.first_name && !data[0]?.last_name) {
            userNames = {
              name: data[0]?.first_name,
              first_name: data[0]?.first_name,
            };
          } else if (data && data[0]?.last_name && !data[0]?.first_name) {
            userNames = {
              name: data[0]?.last_name,
              last_name: data[0]?.last_name,
            };
          } else {
            userNames = {
              name: data[0]?.email.split('@')[0],
              first_name: data[0]?.email.split('@')[0],
            };
          }

          // // @ts-ignore
          // window.Intercom('boot', {
          //   app_id: 'xv9r97eq',
          //   email: user.email,
          //   user_hash: userHash,
          //   user_id: user.id,
          //   ...userNames,
          // });
        });
    }
    // else {
    //   // @ts-ignore
    //   window.Intercom('boot', {
    //     app_id: 'xv9r97eq',
    //     name: '',
    //     first_name: '',
    //     last_name: '',
    //   });
    // }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Supernova</title>
      </Helmet>
      <ChakraProvider resetCSS theme={theme}>
        <Global styles={globalStyles} />
        <Fonts />
        <React.Suspense
          fallback={
            <Flex p={8} h="100vh" w="100vw" align="center" justify="center">
              <Spinner />
            </Flex>
          }
        >
          <BrowserRouter basename="/">
            <Routes>
              <Route
                path="/"
                element={
                  user ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
                }
              />
              {/* <Route
                path="/register"
                exact
                render={(props: RouterProps) => <RegisterPage {...props} />}
              /> */}
              <Route
                path="/login"
                element={<LoginPage />}
              />
              <Route
                path="/forgot-password"
                element={
                  <ForgotPasswordPage />
                }
              />
              <Route
                path="/reset-password"
                element={
                  <ResetPasswordPage />
                }
              />
              <Route
                path="/dashboard"
                element={<DashboardPage />}
              />
              <Route
                path="/pricing"
                element={<PricingPage />}
              />
              <Route
                path="/account-settings/profile"
                element={<ProfileSettings />}
              />
              <Route
                path="/account-settings/reset-password"
                element={<PasswordReset />}
              />
              <Route
                path="/account-settings/notifications"
                element={
                  <EmailNotifications />
                }
              />
              <Route
                path="/account-settings/overagebilling"
                element={<OverageBilling />}
              />
              {/* <Route
                path="/account-settings/no-code-solutions"
                exact
                render={(props: RouterProps) => <NoCodeSolutions {...props} />}
              /> */}
              {/* <Route
                path="/empty-requests"
                exact
                render={(props: RouterProps) => (
                  <EmptyRequestsPage {...props} />
                )}
              />
              <Route
                path="/zapier-landing"
                exact
                render={(props: RouterProps) => (
                  <ZapierLandingPage {...props} />
                )}
              /> */}
              <Route element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </React.Suspense>
      </ChakraProvider>
    </>
  );
};

export default App;
